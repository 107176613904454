.title {
  color: #454545;
  font-size: 24px;
  font-weight: bold;
}

.userBlock {
  display: flex;
  flex-direction: column;
}

.userRow {
  /* border-top: 1px solid; */
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 0px;
}

.listContainer {
  height: 260px;
  overflow-y: scroll;
}

.mem-tbScroll {
  overflow-x: scroll;
}

.checked {
  color: orange !important;
}

.buttonStyle1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  width: 80px;
  height: 24px;
  border-radius: 20px;
}

.membershipUser {
  border-bottom: 1px solid #e7e7e7;
  padding: 15px;
}

.membershioPointsTitle {
  color: #757575;
  font-weight: 600;
  font-size: 13px;
}

.membershioPointsValue {
  color: #454545;
  font-weight: bold;
  font-size: 13px;
}

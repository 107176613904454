.courses-cont {
  height: 790px;
}
.courses-row {
  height: 100%;
}
.courses-col {
  height: 100%;
}
.courses-shadow {
  height: 100%;
}
.courses-tablePag {
  height: 90%;
  max-height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-mainBox {
  min-width: 620px;
  min-height: 681px;
}

.Modal-Course_Edit-Mian {
  min-height: 681px;
}

.Modal-Course_Edit-sub-Color {
  display: flex;
  flex-direction: row;
}

.Modal-Course_Edit-sub {
  margin-left: 40px;
  margin-right: 24px;
}

.Modal-Select-Color {
  font-size: 14px;
  font-weight: 600;
  color: #454545;
  margin-bottom: 8px;
}
.Modal-Select-Color1 {
  font-size: 14px;
  font-weight: 600;
  color: #454545;
}

.Duration_check-Box {
  display: flex;
  flex-direction: row;
}

.Duration_check-Box-1 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 13px;
}
.Duration_check-Box-2 {
  display: flex;
  flex-direction: row;
  margin-left: 37px;
  align-items: flex-end;
  margin-top: 13px;
}
.Level_check-Box {
  display: flex;
  flex-direction: row;
}

.Level_check-Box-1 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 16px;
}
.Level_check-Box-2 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 16px;
}

.Level_check-Box-Secondary {
  margin-left: 37px;
  margin-right: 34px;
}

.coursePricePerHourModal {
  padding: 20px;
}

.updateCourseBtn {
  background-color: rgb(255, 103, 0);
  color: #fff;
  margin-top: 20px;
}

.coursePriceInput {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  /* font-size: 14px; */
  height: 40px;
  padding: 5px;
}

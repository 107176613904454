/* Profile Card TUTOR Start Here */

.ProfileMainCard {
  width: 26.354vw !important;
  height: 22.396vw !important;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(151, 104, 73, 0.15);
}

.ProfileCardButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ProfileInfoCard {
  margin-bottom: 0.26vw;
  border-radius: 12px;
}

.ProfileCard1845 {
  font-size: 0.938vw;
  color: #454545;
}

.ProfileCard149A {
  font-size: 0.729vw;
  color: #9a9a9a;
}

.ProfileCard2045 {
  font-size: 1.042vw;
  color: #454545;
}

.ProfileCard169A {
  font-size: 0.833vw;
  color: #9a9a9a;
}

.ProfileInfoCardValueMLeft {
  margin-left: 1.563vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ProfileInfoCardValueNoM {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ProfileInfoCardValueMRight {
  margin-right: 1.563vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ProfileUserInfoCard {
  display: flex;
  justify-content: space-around;
  background-color: #f6f9fc;
  border-radius: 12px;
  align-items: center;
  margin-top: 1.563vw;
  width: 23.229vw;
  height: 3.49vw;
}

.ProfileCardHR {
  margin: 0.6vw 0px 0.4vw;
}

.ProfileCardMT30 {
  margin-top: 0.8vw;
}

.ProfileColor9A {
  color: #9a9a9a;
}

.ProfileColor12BF0F {
  color: #12bf0f;
}

.ProfileColorff6700 {
  color: #ff6700;
}

/* Profile Card TUTOR Ends Herre */

/* Profile Card TUTEE STARTS Here */

/* Profile Card TUTEE ENDS Herre */

.tuteeIdBlock {
  background: #8898aa;
  color: #fff;
  padding: 7px;
  border-radius: 6px;
  font-weight: 600;
}

.buttonTabs {
  width: 70px;
  height: 35px;
  font-size: 90%;
  margin: 10px;
  text-align: center;
  background-color: #ff6700;
  color: white;
  border-radius: 5px;
  padding-top: 5px;
  border-color: #ff6700;
  cursor: pointer;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  font-weight: bold;
}

.underLine {
  position: relative;
}

.qualificationRequest {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.underLine::after {
  content: "";
  position: absolute;
  top: 120%;
  left: 22%;
  width: 50%;
  height: 0.2rem;
  margin: auto;
  background-color: #ff6700;
}

.buttonLight {
  width: 70px;
  height: 35px;
  font-size: 90%;
  margin: 10px;
  text-align: center;
  background-color: #fff8f1;
  color: #ff6700;
  border-radius: 5px;
  border: 0.5px solid #ffd2b4;
  padding-top: 5px;
}

/* .buttonTabs:hover::after{
    width: 100%;
    text-decoration: underline;
} */

.btn3 {
  height: 100%;
  width: 100%;
  max-width: 100%;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  text-decoration-color: #0000;
  background-color: #fffbf6;
  border: 0.5px solid #ffd2b4;
}

.btn1 {
  height: 25px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  text-decoration-color: #0000;
  background-color: #fffbf6;
  border: 0.5px solid #ffd2b4;
}

.stars {
  color: #ffc22b;
}

.lable {
  width: 45px;
  height: 35px;
  padding-top: 10px;
  /* margin-top: 10px; */
  text-align: center;
  background-color: #ff6700;
  color: white;
  border-radius: 5px;
}

.lable1 {
  width: 45px;
  height: 45px;
  padding: auto;
  margin: 10px;
  text-align: center;
  font-size: 0.8vw;
  background-color: #ff6700;
  color: white;
  border-radius: 5px;
  border-color: #ff6700;
}

.buttonTabs1 {
  width: 80px;
  height: 30px;
  /* margin: 10px; */
  padding: 5px;
  text-align: center;
  background-color: #ff6700;
  color: white;
  border-radius: 5px;
  border-color: #ff6700;
  font-size: 0.8vw;
}

.userDropdown {
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  text-decoration-color: #0000;
  background-color: #fff;
  border-color: #ffff;
}

.namebutton {
  width: 2.604vw !important;
  height: 1.042vw !important;
  margin: 0.521vw !important;
  text-align: center;
  background-color: #ff6700;
  color: white;
  border-radius: 5px;
  border-color: #ff6700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.namebutton1 {
  width: 8vw !important;
  height: 1.5vw !important;
  margin: 0.521vw !important;
  text-align: center;
  background-color: #ff6700;
  color: white;
  border-radius: 5px;
  border-color: #ff6700;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .icon{
    color:#ff6700 ;
  } */

.vl {
  padding-right: 10px;
  border-radius: 3px;
  border-left: 2px solid green;
  height: 40px;
}

.buttonStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #e9ecef;
  font-size: 0.9vw;
  /* margin-bottom:0.313vw ; */
  width: 10.625vw;
  height: 1.406vw27px;
  border-radius: 18px;
  cursor: pointer;
}

.Active {
  background-color: #12bf0f;
  border-radius: 20px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.6vw;
}

.InActive {
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.6vw;
}

.supportButton {
  border: 0.5px solid #ff6700;
  background-color: #fff;
  color: #ff6700;
  padding: 3px 4px;
  width: 109px;
  height: 28px;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

.supportButton1 {
  border: 1px solid #ff6700;
  color: #ff6700;
  padding: 2px 4px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.supportButton3 {
  border: 1px solid #ff6700;
  background-color: #fff;
  color: #ff6700;
  padding: 2px 4px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8vw;
  cursor: pointer;
  width: 60px;
}

.arrowStyle {
  color: #ff6700;
}

.TutorTutee {
  width: 191px;
  height: 28px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  background-color: #fff;
  color: #ff6700;
  padding: 3px;
  margin-right: 30px;
  border-radius: 5px;
  border: 1px solid #ff6700;
  cursor: pointer;
}

.sendButton {
  width: 85px;
  height: 26px;
  margin: 10px;
  text-align: center;
  background-color: #ff6700;
  color: white;
  border-radius: 5px;
  border-color: #ff6700;
  font-size: 14px;
  padding: 4px 3px;
}

.ButtonType {
  width: 120%;
  height: 30px;
  font-size: 0.7vw;
  text-align: center;
  background-color: #fff;
  color: #ff6700;
  border-radius: 5px;
  border: 1px solid #ff6700;
  cursor: pointer;
}

.arrowButton {
  height: 100%;
  width: 100%;
  padding: 50%;
  font-size: 0.7vw;
  text-align: center;
  background-color: #fff;
  color: #ff6700;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #ff6700;
  cursor: pointer;
}

.centerText {
  text-align: center;
  border: 1px solid #ff6700;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 5px;
  color: #ff6700;
  font-size: 100%;
  cursor: pointer;
}

.centerText1 {
  width: 130px;
  height: 37px;
  text-align: center;
  background-color: #ff6700;
  border-radius: 5px;
  color: #fff;
  font-size: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 10px;
}

.label2 {
  width: 65px;
  height: 32px;
  padding-top: 2px;
  padding-bottom: 10px;
  margin: 10px;
  text-align: center;
  background-color: #fb6340;
  color: white;
  border-radius: 4px;
}

.label3 {
  width: 100px;
  height: 32px;
  padding-top: 2px;
  padding-bottom: 10px;
  margin: 10px;
  text-align: center;
  background-color: #fb6340;
  color: white;
  border-radius: 4px;
}

/* <<<<<<< HEAD
.expiredButton{
  width: 200%;
  height: 20px;
  margin: 10px;
  text-align: center;
  color: white;
  border-radius: 5px;
  background-color: #FF9696;

.arrow_box {
	position: relative;
	background: #E9F4FF;
	border: 1px solid #E9F4FF;
  width: 100px;
}
.arrow_box:after, .arrow_box:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:after {
	border-color: rgba(136, 183, 213, 0);
	border-left-color: #E9F4FF;
	border-width: 25px;
	margin-top: -30px;
}
.arrow_box:before {
	border-color: rgba(194, 225, 245, 0);
	border-left-color: #c2e1f5;
	border-width: 30px;
	margin-top: -36px; 
} */

.modalFooter {
  width: 100%;
  height: 30px;
  margin: 10px;
  text-align: center;
  background-color: #ff6700;
  color: white;
  border-radius: 5px;
  border-color: #ff6700;
}

.lButton {
  width: 100%;
  height: 50px;
  margin: 10px;
  text-align: center;
  background-color: #ff6700;
  color: white;
  border-radius: 5px;
  border-color: #ff6700;
}

.reset_password_btn {
  background-color: #2354d7 !important;
  border-color: #7393e5;
  margin: 0px !important;
}

.resetPasswordLogo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 30px 15px;
}

.grooveBorder {
  border-style: groove;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
}

.grooveBorder2 {
  background-color: #ededed;
  border-radius: 20px;
  color: #757575;
  height: 31px;
  width: 35%;
  padding-top: 3.3px;
  text-align: center;
  cursor: pointer;
  margin: 10px 10px;
}

.grooveBorder3 {
  text-align: center;
  height: 31px;
  padding-top: 3.3px;
  width: 35%;
  cursor: pointer;
}

.secondary1 {
  background-color: #ededed;
  border-radius: 20px;
  color: #757575;
  height: 31px;
  width: 100%;
  font-size: 0.8vw;
  padding-top: 3.3px;
  text-align: center;
  cursor: pointer;
  margin: 10px;
  /* margin-top: 30px;
  margin-right: 15px;
  margin-left: 15px; */
}

.secondary2 {
  text-align: center;
  height: 31px;
  padding-top: 3.3px;
  width: 28%;
  cursor: pointer;
}

.breadCrumb {
  max-width: 50%;
  min-width: 100px;
  background: #fff;
  color: #32325d;
  border-radius: "8px";
  padding: 2px;
  margin: 3px;
  border-radius: 2px;
  /* border:1px solid #ffdd7c; */
  float: left;
  clear: left;
}

/* .vl {
  border-left: 6px solid black;
  height: 100px;
  position:absolute;
  top: 50%;
} */

.speech-bubble {
  position: relative;
  background: #e9f4ff;
  border-radius: 0.4em;
  border: 1px solid #aecbe9;
}

/* .speech-bubble:after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-left-color: #00aabb;
	border-right: 0;
	margin-top: -20px;
	margin-right: -20px;
} */

.searchStyle {
  border-radius: 30px;
  border: 1px solid #e9ecef;
  width: 20%;
}

.main1 {
  width: 210px;
  /* margin: auto; */
}

.main2 {
  width: 300px;
  /* margin: auto; */
}

/* Bootstrap 4 text input with search icon */

/* .custom-checkbox input {
  display: none;
} */

.custom-checkbox:hover span,
/* .custom-checkbox input:checked+span {
  border: 3px solid #ff6700;
} */

.custom-checkbox input:checked+span:before {
  content: "✔";
  color: #ff6700;
  /* color: green; */
}

/* * {
	box-sizing: border-box;
	font-family: "Arial";
} */
.wrapper-dropdown {
  position: relative;
  width: 200px;
  background: #fff;
  color: #2e2e2e;
  outline: none;
  cursor: pointer;
}

.wrapper-dropdown > span {
  width: 100%;
  display: block;
  border: 1px solid #ababab;
  padding: 5px;
}

.wrapper-dropdown > span > span {
  padding: 0 12px;
  margin-right: 5px;
}

.wrapper-dropdown > span:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 16px;
  top: calc(50% + 4px);
  margin-top: -6px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #2e2e2e transparent;
}

.wrapper-dropdown .dropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  font-weight: normal;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  border: 1px solid #ababab;
  border-top: 0;
}

.wrapper-dropdown .dropdown li {
  display: block;
  text-decoration: none;
  color: #2e2e2e;
  padding: 5px;
  cursor: pointer;
}

.wrapper-dropdown .dropdown li > span {
  padding: 0 12px;
  margin-right: 5px;
}

.wrapper-dropdown .dropdown li:hover {
  background: #eee;
  cursor: pointer;
}

.activtyLogShadow {
  box-shadow: 0 0 15px rgba(189, 195, 199, 1);
  clip-path: inset(0px -15px 0px 0px);
  z-index: 1;
}

.tab1 {
  color: #454545;
  cursor: pointer;
  font-size: 88%;
}

.underline1 {
  position: relative;
}

.underline1::after {
  content: "";
  position: absolute;
  top: 120%;
  left: 0%;
  width: 100%;
  height: 0.2rem;
  margin: auto;
  background-color: #454545;
}

.tab2 {
  color: #9a9a9a;
  font-size: 88%;
}

.con {
  width: 200px;
  height: 150px;
  /* border-radius:75px; */
  background-image: url("../../assets/img/theme/certificate.jpg");
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.overlay {
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 10px;
  text-align: left;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: top 0.5s;
  top: 50%;
}

/* .con:hover .overlay{
  top:50%;
} */
.overlay p {
  margin: 5px;
  color: white;
  font-size: 1.6em;
}

/* AdminUSer page styling starts here */

.adminUser-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
}

.adminUser-cont {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.adminUser-media {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.adminUser-set {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

/* AdminUSer page styling ends */

/*     Start    TutionDetails          */

.tuitionDetails-rescedullebtn {
  display: flex;
  justify-content: end;
  width: 97%;
  position: relative;
  top: 56px;
}

.Infoid-info {
  display: flex;
  margin: 6px 30px 0px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.InfoID {
  color: #454545;
  font-size: 14px;
  font-weight: 600;
}

.Info-Info {
  color: #9a9a9a;
  font-size: 14px;
  font-weight: 600;
}

.Info-Info-Week {
  color: #ff6700;
  font-size: 14px;
  font-weight: 600;
}

.Tutor-Requests-Top {
  height: 75px;
  width: 616px;
  display: flex;
  align-items: center;
  padding-left: 31px;
}

/*         TutionDetails     End          */
.PrimaryScrollQualification {
  max-height: 231px;
  overflow-y: scroll;
  padding: 0px 30px 30px;
}

.PrimaryScrollQualification::-webkit-scrollbar {
  width: 3px !important;
  margin-right: 5px;
}

.PrimaryScrollQualification::-webkit-scrollbar-track {
  background: transparent;
}

.PrimaryScrollQualification::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.PrimaryScrollQualification1122 {
  max-height: 16.406vw;
  overflow-y: scroll;
}

.PrimaryScrollQualification1122::-webkit-scrollbar {
  width: 3px !important;
  margin-right: 5px;
}

.PrimaryScrollQualification1122::-webkit-scrollbar-track {
  background: transparent;
}

.PrimaryScrollQualification1122::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.PrimaryScrollQualification1111 {
  height: 150px;
  overflow-y: scroll;
}

.PrimaryScrollQualification1111::-webkit-scrollbar {
  width: 3px !important;
  margin-right: 5px;
}

.PrimaryScrollQualification1111::-webkit-scrollbar-track {
  background: transparent;
}

.PrimaryScrollQualification1111::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.table th,
.table td {
  padding: 1rem 0rem 1rem 0.5rem;
}

.conversiationModal {
  max-width: 999px !important;
  max-height: 802px !important;
}

.reschedule-modal {
  max-width: 600px !important;
}

.rescheduleFieldValue {
  border: 1px solid #bcbcbc66;
  border-radius: 4px;
  padding: 10px;
}

.rescheduleFieldLabel {
  color: #454545;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
}

/* Search setting for users start here */
.users-has-search .users-form-control {
  padding-left: 2.375rem;
  border-radius: 25.5px;
  border: 1px solid #9a9a9a;
  background-color: #fffbf6;
  color: #757575;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  text-decoration: underline;
}

.users-has-search .users-form-control::placeholder {
  color: #757575;
}
.addAdminModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.checkBoxAddAdmin {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0rem;
}
.checkBoxAddAdmin100 {
  width: 100%;
}
.users-has-search .users-form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  line-height: 2.4rem;
  text-align: center;
  pointer-events: none;
  color: #757575;
}

/* Search setting for users ends here */
.user-cont {
  height: 790px !important;
}

.user-row {
  height: 100%;
}

.user-col {
  height: 100%;
}

.user-shadow {
  height: 100%;
}

.user-search {
  width: 182px;
  height: 38px;
}

.user-search-control {
  width: auto;
}

.user-search-input {
  width: -webkit-fill-available;
  height: 38px;
}

.user-tablePag {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.unstyledTab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tablist {
  position: absolute;
  top: 2%;
}

.table-body {
  height: 600px;
  overflow-y: scroll;
}

/* .users-tableData1 {
  width: 11.8vw !important;
}

.users-tableData2 {
  width: 12vw !important;
}

.users-tableData3 {
  width: 5vw !important;
}

.users-tableData4 {
  width: 7vw !important;
}

.users-tableData5 {
  width: 6vw !important;
}

.users-tableData6 {
  width: 9vw !important;
}

.users-tableData7 {
  width: 5vw !important;
}

.users-tableData8 {
  width: 4vw !important;
}

.users-tableData9 {
  width: 4vw !important;
}

.users-tableData10 {
  width: 4vw !important;
} */

.UTTH1 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  width: calc(100% / 10);
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}

.UTTH2 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  /* width: 13vw; */
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}

.UTTH3 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  /* width: 5.99vw; */
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}

.UTTH4 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  /* width: 8.4vw; */
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}

.UTTH5 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  padding-right: 0rem !important;
  /* width: 7.615vw; */
  padding-left: 1.25vw !important;
}

.UTTH6 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  /* width: 8.3vw; */
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}

.UTTH7 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  /* width: 5.625vw; */
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}

.UTTH8 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  /* width: 4.7vw; */
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}

.UTTH9 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  /* width: 5vw; */
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}

.UTTH10 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  /* width: 5vw; */
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}

.UTTTD1 {
  width: 385.75px;
}
.UTTTD2 {
  width: 333.69px;
}
.UTTTD3 {
  width: 165.55px;
}
.UTTTD4 {
  width: 176.28px;
}
.UTTTD5 {
  width: 154.53px;
}
.UTTTD6 {
  width: 129.27px;
}
.UTTTD7 {
  width: 123.45px;
}

.UTTTH1 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  width: calc(100% / 7);
  /* width: 18.066vw; */
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}
.UTTTH2 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  width: 15.628vw;
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}
.UTTTH3 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  width: 7.753vw;
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}
.UTTTH4 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  width: 7.602vw;
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}
.UTTTH5 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  width: 6.739vw;
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}
.UTTTH6 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  width: 6.054vw;
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}
.UTTTH7 {
  font-size: 12px;
  font-weight: 700;
  font-family: Open Sans;
  font-style: normal;
  width: 14.644vw;
  padding-right: 0rem !important;
  padding-left: 1.25vw !important;
}

.UATH1 {
  font-size: 12px;
  font-weight: 600;
  font-family: Open Sans;
  font-style: normal;
  width: calc(100% / 6);
  /* width: 401.94px; */
}
.UATH2 {
  font-size: 12px;
  font-weight: 600;
  font-family: Open Sans;
  font-style: normal;
  width: 280.998px;
}
.UATH3 {
  font-size: 12px;
  font-weight: 600;
  font-family: Open Sans;
  font-style: normal;
  width: 221.58px;
}
.UATH4 {
  font-size: 12px;
  font-weight: 600;
  font-family: Open Sans;
  font-style: normal;
  width: 225.83px;
}
.UATH5 {
  font-size: 12px;
  font-weight: 600;
  font-family: Open Sans;
  font-style: normal;
  width: 173.02px;
}
.UATH6 {
  font-size: 12px;
  font-weight: 600;
  font-family: Open Sans;
  font-style: normal;
  width: 165.17px;
}

.UTR {
  /* height: 70.48px; */
  height: 4.167vw;
}
.card .table td,
.card .table th {
  padding-left: 1.25vw !important;
  padding-right: 1.25vw !important;
  padding-top: 0.833vw !important;
  padding-bottom: 0.833vw !important;
}

@media screen and (max-width: 1680px) {
  .UTTH10 {
    width: 6.35vw;
  }
  .UTTTH1 {
    width: 19vw;
  }
  .UTTTH2 {
    width: 17vw;
  }
  .UTTTH7 {
    width: 13.2vw;
  }
}
@media screen and (max-width: 1600px) {
  .UTTH6 {
    width: 7vw;
  }
  .UTTH7 {
    width: 5vw;
  }
  .UTTH8 {
    width: 5.5vw;
  }
  .UTTTH1 {
    width: 19.6vw;
  }
  .UTTTH7 {
    width: 12vw;
  }
}

@media screen and (max-width: 1565px) {
  .UATH1 {
    width: 436.77px;
  }
  .UATH2 {
    width: 280.998px;
  }
  .UATH3 {
    width: 206.58px;
  }
  .UATH4 {
    width: 201.83px;
  }
  .UATH5 {
    width: 173.02px;
  }
  .UATH6 {
    width: 87.17px;
  }
  .card .table td,
  .card .table th {
    padding-left: 0.8rem !important;
    padding-right: 0rem !important;
  }
}
@media screen and (max-width: 1440px) {
  .UTTH5 {
    width: 8.615vw;
  }
  .UTTH6 {
    width: 8vw;
  }
  .UTTH7 {
    width: 5.8vw;
  }
  .UTTH8 {
    width: 5vw;
  }
  .UTTH9 {
    width: 5.8vw;
  }
  .UTTH10 {
    width: 3.35vw;
  }
  .UTTTH1 {
    width: 20.4vw;
  }
}

/* Lead pagge styling starts here */
.lead-hr {
  border-width: 0;
  color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.2);
  width: 90%;
}
.lead-ShadowCard {
  max-height: 500px;
  min-height: 500px;
}
.lead-cardHeader-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
}
.lead-cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 19px !important;
  margin: 0px !important;
}
.lead-mainCont {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40%;
}
@media only screen and (max-width: 1444px) {
  .lead-mainCont {
    width: 50%;
  }
}
.lead-userCard-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 25px;
}
.lead-dropdown-btn {
  width: auto;
  /* height: 5vh !important; */
  padding: 4px 14px;
  background-color: #ffffff;
    border-radius: 5px;
    font-weight: 600;
  border: 0.5px solid #ffd2b4;
  text-align: center;
}
.lead-dropdown {
  width: 150px;
  font-size: 14px;
  color: #9a9a9a;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
.lead-dropdown-option {
  background-color: #ffffff;
  color: #ff6700;
  height: 400px;
}
.lead-userChange {
  width: 45%;
}
.lead-tr {
  width: 100%;
  height: 100%;
  margin-left: 2rem;
}
.lead-tr2 {
  width: 100%;
  height: 100%;
  margin-left: 5rem;
}
.lead_adminDetailCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}
.lead-detailP {
  font-size: 14px;
  margin-top: 20px;
  color: #9a9a9a;
}
.leads-cont {
  width: 1550px;
}
.lead-userName {
  font-size: 14px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
}
.thead-light {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 12px !important;
  font-weight: 700;
  line-height: 12px;
}
.lead-alltutorHeader {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}
/* Lead pagge styling ends */

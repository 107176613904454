//
// Icon shape
//


.icon-shape {
	// padding: 20px;
	text-align: center;
	display: flex;
	flex-direction: row;
	// align-items: center;	
	// justify-content: center;	
	border-radius: 50%;
	width: 48px;
    height: 48px;

	i, svg {
		font-size: 1.25rem;
	}

	&.icon-lg {
		i, svg {
			font-size: 1.625rem;
		}
	}

	&.icon-sm {
		i, svg {
			font-size: .875rem;
		}
	}

	svg {
		width: 50px;
		height: 50px;

	}

}

@each $color, $value in $theme-colors {
	.icon-shape-#{$color} {
		@include icon-shape-variant(theme-color($color));
	}
}

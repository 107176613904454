@media only screen and (max-width: 1444px) {
    .sideBar-cont {
        width: 13.021vw !important;
    }
}
.sideBar-navLink::before {
    border-left: none !important;
}
.leads {
    width:100%;
    height:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
}
.leadsText {
    width:100%;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    /* justify-content: space-between; */
}
.leadsPillActive {
    height: 22px;
    aspect-ratio: 1 / 1;
    font-size: 0.9vw !important;
    border-radius: 50%;
    background-color: #fff;
    color: #333333;
    /* color: #ff6700; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
.leadsPill {
    height: 22px;
    aspect-ratio: 1 / 1;
    font-size: 0.9vw !important;
    border-radius: 50%;
    background-color: #ff6700;
    color: #fff;
    /* color: #ff6700; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
.jobStatus {
  font-size: 12px;
  color: #e9ecef;
  font-weight: 600;
  background-color: rgb(233, 236, 239);
  height: 28px;
  width: 90px;
  text-align: center;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerCol66 {
  max-width: 255px;
  padding-right: 0px;
  padding-left: 0px;
}
.headerCol1366 {
  max-width: 180px;
  padding-right: 0px;
  padding-left: 0px;
}

.headerRow {
  margin-bottom: 6rem;
}
@media only screen and (max-width: 1366px) {
  .headerCol {
    max-width: 255px !important;
    padding-right: 0px;
    padding-left: 0px;
  }
  .headerCol1 {
    max-width: 180px !important;
    padding-right: 0px;
    padding-left: 0px;
  }
  .header-card {
    margin-right: 0;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 1444px) {
  .headerCol {
    max-width: 260px !important;
    padding-right: 0px;
    padding-left: 0px;
  }
  .headerCol1 {
    max-width: 190px !important;
    padding-right: 0px;
    padding-left: 0px;
  }
  .header-card {
    margin-right: 0;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 1440px) {
  .headerCol {
    max-width: 260px !important;
    padding-right: 0px;
    padding-left: 0px;
  }
  .headerCol1 {
    max-width: 190px !important;
    padding-right: 0px;
    padding-left: 0px;
  }
  .header-card {
    margin-right: 0;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 1680px) {
  .headerCol {
    max-width: 320px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .headerCol1 {
    max-width: 210px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .header-card {
    margin-right: 0;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 1600px) {
  .headerCol {
    max-width: 310px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .headerCol1 {
    max-width: 210px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .header-card {
    margin-right: 0;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
  }
}

.reviewContainer {
  padding: 50px;
}

.reviewHeader {
  display: flex;
  justify-content: space-between;
}

.reviewsTitle {
  font-size: 24px;
  font-weight: 600;
  color: rgb(51, 51, 51);
}

.reviewerName {
  font-weight: 700;
  font-size: 18px;
}

.reviewClass {
  font-weight: 500;
  color: rgb(154, 154, 154);
}

.reviewText {
  font-style: italic;
  color: #000;
}

.reviewBlock {
  padding-bottom: 15px;
  padding-top: 15px;
  line-height: 2;
}

.line-1 {
  line-height: 1.5;
}

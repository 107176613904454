/* Voucher page Styling starts here */

.voucher-row {
  display: flex !important;
  justify-content: space-evenly;
  flex-direction: "row";
}
.voucher-card-head {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;
}
.voucher-create-btn {
  background-color: #fff;
  border: 1px solid #ff6700;
  color: #ff6700;
}
.voucher-data {
  margin: 20px 15px;
  border-radius: 8px;
  border: 1px solid #d3e0ed;
}
.voucher-card-head1 {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 7.5px 0px;
}
.voucher-btn {
  background-color: #fff;
  border: 1px solid #ff6700;
  color: #ff6700;
}
.voucher-table-data {
  font-size: 100%;
  color: #fff;
  height: 20px;
}
.voucher-modal-body {
  display: flex;
  justify-content: space-between;
  margin: 10px 15px;
  font-weight: bold;
}
.voucher-modal-data {
  display: flex;
  justify-content: space-between;
  /* margin-top: -30px; */
  margin-bottom: 10px;
}
.voucher-select-btn {
  background-color: #fffbf6;
  color: #575757;
  width: "200px";
}
.voucher-choose-tutee {
  margin: 20px 0px;
}
.input_select_design {
  width: 90%;
  border-color: lightgray;
  border-radius: 5px;
}
.voucher-addMember-data {
  display: flex;
  flex-direction: row;
  /* margin-top: 24px; */
  /* margin-left: 24px; */
  padding: 20px;
}

.voucher-addMember-data > div {
  width: 100%;
}

/* Voucher page Styling end */
.globalVoucherPill {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 70px;
  border-radius: 5rem;
  color: #fff;
  background-color: #ff6700;
}

.modalFooter:hover {
  background-color: #ff6700;
}

.voucherTabRow {
  background: #fff;
  border-radius: 0.375rem;
  z-index: 99999;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
}

.shortDescription {
  display: flex;
  justify-content: space-between;
}

.shortDescCount {
  color: rgb(154, 154, 154);
}

.voucherTabRow {
  background: #fff;
  border-radius: 0.375rem;
  // z-index: 99999;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 2px;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
}

.topVoucherRowCol {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.voucherTitle {
  color: #454545;
  font-weight: 600;
  font-size: 20px;
}

.voucherName {
  display: flex;
  align-items: center;
  gap: 10px;
}

.voucherNameHeading {
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  gap: 12px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionDropdownBtn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
}

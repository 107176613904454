.jobPosting-search {
  display: flex;
  align-items: center;
  height: 1.979vw;
}

.has-search .form-control:hover{
  background-color: alpha(#F7FAFC, 0.5);

}
.has-search .form-control {
  padding-left: 2.5vw;
  padding-bottom: 0.521vw;
  padding-top: 0.521vw;
  border-radius: 25.5px;
  width: 14.583vw;
  font-size: 0.729vw;
  border: 1px solid #ced4da;
  background-color: #ffffff;
}
.has-search .form-control::placeholder {
  color: #333333;
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  font-size: 0.833vw;
  pointer-events: none;
  color: #333333;
  margin-top: 15px;
  right: 14.2vw;
}
.has-search1 .form-control1:hover{
  background-color: alpha(#F7FAFC, 0.5);
}
.has-search1 .form-control1 {
  padding-left: 2vw;
  padding-bottom: 0.521vw;
  padding-top: 0.521vw;
  border-radius: 25.5px;
  width: 15vw;
  font-size: 0.729vw;
  border: 1px solid #ced4da;
  background-color: #ffffff;
}
.has-search1 .form-control1::placeholder {
  color: #333333;
}
.has-search1 .form-control-feedback1 {
  position: absolute;
  z-index: 2;
  display: block;
  font-size: 0.833vw;
  pointer-events: none;
  color: #333333;
  margin-top: 15px;
  right: 16.2vw;
}

.ModalDeleteText {
  font-family: Open Sans, sans-serif;
  font-size: 22px;
  line-height: 38.13px;
  color: #525f7f;
  text-align: left;
  font-weight: 600;
}
.DeleteModalMain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
}

.DeleteOk {
  background-color: rgb(255, 103, 0);
  border: 1px solid rgb(255, 103, 0);
  color: rgb(255, 255, 255);
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  width: 130px;
  font-size: 18px;
  height: 39px;
}

.DeleteCancle {
  background-color: #fff8f1;
  border: 0.5px solid #ffd2b4;
  color: #ff6700;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  width: 130px;
  margin-right: 10px;
  font-size: 18px;
  height: 39px;
}

.DeleteButtons {
  display: flex;

  justify-content: end;
}
.jobPost-delete-model {
  max-width: 600px !important;
}

.jobpostDeletData {
  color: #5e72e4;
  font-size: 100%;
  font-weight: 600;
}
.jobpostSureAboutDeleting {
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  line-height: 38.13px;
  color: #525f7f;
  text-align: left;
  font-weight: 600;
}
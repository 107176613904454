.talk-bubble {
	margin: 10px;
    display: inline-block;
    position: relative;
	max-width: 60%;
	height: auto;
	background-color: #fff;
    border-radius: 10px;
}

.talktext{
    padding: 1em;
    text-align: left;
    line-height: 1.5em;
  }

  .talktext p{
    /* remove webkit p margins */
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    font-size: 14px;
  }

  .talk-bubble-right {
	margin: 10px;
    /* display: inline-block; */
    position: relative;
	max-width: 60%;
	height: auto;
    color: #fff;
	background-color: #454545;
    border-radius: 10px;
    margin-left: auto;
}

.talktext1{
    padding: 1em;
    text-align: left;
    line-height: 1.5em;
    font-size: 10px;
    margin-left: auto;
  }

  .talktext1 p{
    /* remove webkit p margins */
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    font-size: 14px;
  }
.PrimaryScrollQualification33 {
  padding: 1.563vw;
  max-height: 16.875vw;
  overflow-y: scroll;
}

.PrimaryScrollQualification33::-webkit-scrollbar {
  width: 3px !important;
  margin-right: 5px;
}

.PrimaryScrollQualification33::-webkit-scrollbar-track {
  background: transparent;
}

.PrimaryScrollQualification33::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.dropdownField {
  color: #575757;
  width: 100%;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  text-decoration-color: #0000;
  background-color: #fffbf6;
  border: 0.5px solid #ffd2b4;
}

.PrimaryScrollQualification11 {
  max-height: 16.875vw;
  overflow-y: scroll;
}

.PrimaryScrollQualification11::-webkit-scrollbar {
  width: 3px !important;
  margin-right: 5px;
}

.PrimaryScrollQualification11::-webkit-scrollbar-track {
  background: transparent;
}

.PrimaryScrollQualification11::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

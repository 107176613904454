/* SupportModal styling starts here */

.containerSupportModel {
  height: 100vh;
  width: 100vw;
  z-index: 1000;
}

.pageCoverSupport {
  display: flex;
  flex-direction: column;
  width: 115%;
  height: 100%;
  position: fixed;
  right: 0%;
  top: 0%;
  bottom: 0%;
  z-index: 100000000;
}

.darkpageCoverSupport {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  background-color: black;
  opacity: 0.16;
}

.mainSupportModel {
  display: flex;
  flex-direction: column;
  width: 768px;
  height: 100vh;
  position: absolute;
  z-index: 100000000;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
  border-radius: 12px 0px 0px 12px;
}

.buttonTabs11 {
  width: 101px;
  height: 35px;
  font-size: 90%;
  margin: 10px;
  text-align: center;
  background-color: #ff6700;
  color: white;
  border-radius: 5px;
  padding-top: 5px;
  border-color: #ff6700;
  cursor: pointer;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  font-weight: bold;
}

.buttonLight11 {
  width: 98px;
  height: 35px;
  font-size: 90%;
  margin: 10px;
  text-align: center;
  background-color: #fff8f1;
  color: #ff6700;
  border-radius: 5px;
  border: 0.5px solid #ffd2b4;
  padding-top: 5px;
}

.SuppInboxClose {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  left: 1192px;
  top: 30px;
  border-radius: 4px;
  background: #fffbf6;
  border: 1px solid #e2d1bc;
  border-radius: 4px;
}

.SuppInboxClose {
  margin-left: 40px;
}

.SuppInboxText {
  margin: 0px 221px 0px 24px;
}

.SuppDivider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.divider {
  height: 2px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #e9ecef;
  background-color: #e9ecef;
}

.divider1 {
  height: 2px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #bcd6f1;
  background-color: #bcd6f1;
}

.support-userType {
  margin-left: 4px;
}

.ChatSenderSuppMyInbox {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
}

.ChatSenderSuppMyInbox2 {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
}

.TuteeLabelCoverBox {
  height: 17px;
  width: 45px;
  margin-top: 3px;
  background: #008fbc;
  display: flex;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
}

.ChatSentTimeSuppMyInbox {
  /* margin-left:395px; */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #9a9a9a;
}

.TuteeLabelCover {
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.SupportBoxContainor {
  display: flex;
  flex-direction: row;
}
.SupportBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 101px !important;
  width: 768px;
  justify-content: space-between;
}

.PrimaryBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* overflow: auto; */
  /* overflow-y: scroll; */
}

.PrimaryBox::-webkit-scrollbar {
  width: 3px !important;
  margin-right: 5px;
}

.PrimaryBox::-webkit-scrollbar-track {
  background: transparent;
}

.PrimaryBox11::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.PrimaryBox11::-webkit-scrollbar {
  width: 3px !important;
  margin-right: 5px;
}

.PrimaryBox11::-webkit-scrollbar-track {
  background: transparent;
}

.PrimaryBox11::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.navbar-vertical::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.navbar-vertical::-webkit-scrollbar {
  width: 8px !important;
  margin-right: 5px;
}

.navbar-vertical::-webkit-scrollbar-track {
  background: transparent;
}

.navbar-vertical::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.supportChatMyInboxDivider {
  display: flex;
  flex-direction: column;
  height: 73px;
  width: 100%;
  padding: 6px 16px 11px;
}

.SuppInboxText {
  font: "Open Sans";
  font-weight: 600;
  font-style: "normal";
  font-size: 28px;
  line-height: 38.13px;
}

.supportChatMyInbox {
  display: flex;
  flex-direction: row;
  padding: 6px 0px 6px;
  height: 61px;
  border-radius: 6px;
  justify-content: space-between;
  cursor: pointer;
}

.supportChatMyInbox1 {
  display: flex;
  flex-direction: row;
  padding: 6px 0px 6px;
  height: 61px;
  border-radius: 6px;
  margin-left: 10px;
  cursor: pointer;
}

.supportChatMyInbox:hover {
  background-color: #e2f0ff;
  cursor: pointer;
}

.supportChatMyInbox1:hover {
  background-color: #e2f0ff;
  cursor: pointer;
}

.ChatMsgSuppMyInboxText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 626px;
}

.ChatInfoSuppMyInbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 3px;
}

.ChatSenderTitleSuppMyInbox {
  display: flex;
  flex-direction: row;
}

.ProfileMsgInfoSuppMyInbox {
  margin-left: 9px;
}

/* SupportModal styling ends */

/* SupportModal All Chat Styling Starts */

.SuppAllChatAgent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 41px;
  background-color: #f6f9fc;
  align-items: center;
  padding: 14px 40px 15px;
  overflow: hidden;
}

.SuppAllChantAgentText {
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.8999999761581421px;
  text-align: left;
  color: #9a9a9a;
  opacity: 0.99;
}

.PrimaryBox11 {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 91px);;
  width: 100%;
  overflow: auto;
}

.PrimaryBox22 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: #f6f9fc;
  /* overflow-y: scroll; */
}

.LeftSuppAllChat {
  display: flex;
  flex-direction: row;
}

.AgentAndTitleBoxSupp {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
}

.RightSuppAllChat {
  display: flex;
  flex-direction: row;
}

.AgentAndTitleBoxSupp2 {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 7px;
}

.MeLabelCoverBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 17px;
  background-color: #12bf0f;
  border-radius: 2px;
}

.MeLabelSuppChat {
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.TUTORLabelSuppChat {
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.SuperAdminLabelSuppChat {
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.SuperAdminLabelCoverBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-size: 12px;
  height: 17px;
  background-color: #9a9a9a;
  border-radius: 2px;
  padding: 0px 5px;
}

.TUTORLabelSuppChatBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49px;
  height: 17px;
  background-color: #00bba5;
  border-radius: 2px;
}

/* SupportModal All Chat Styling Ends */

.SuppAllChatAgent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  width: 765px;
  height: 61px;
  background-color: #e2f0ff;
}

.RightSuppAllChat1 {
  display: flex;
  flex-direction: row;
}

.BackToChat {
  display: flex;
  align-items: center;
  padding-left: 30px;
  height: 51px;
  background-color: #ffffff;
}

.BackToChatTextBold {
  margin-left: 8px;
  margin-top: 2px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #454545;
}

.SuppChatMsgBox {
  display: flex;
  flex-direction: column;
  padding: 0px 30px 0px;
  height: 67vh;
  /* overflow-y: scroll; */
}

.SuppChatMsgBox::-webkit-scrollbar {
  width: 3px !important;
  margin-right: 5px;
  display: flex;
  flex-direction: column-reverse;
}

.SuppChatMsgBox::-webkit-scrollbar-track {
  background: transparent;
}

.SuppChatMsgBox::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.SuppChatMsgRecived {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
}

.SuppChatMsgRecivedMsgBox {
  height: auto;
  width: 456px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  padding: 10px 16px;
}

.SuppChatMsgRecivedMsg {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 0px !important;
}

.ProfilePicSuppMyInbox1 {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  margin: 10px 12px 10px;
}

.ProfilePicSuppMyInbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SuppChatMsgRecivedMsgTime {
  margin-top: 8px;
}

.SuppChatMsgRecivedMsgTimeText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9a9a9a;
}

.SuppChatMsgSent {
  display: flex;
  justify-content: flex-end;
}

.SuppChatMsgRecivedMsgBox1 {
  height: auto;
  width: 456px;
  border-radius: 6px;
  background: #454545;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  padding: 10px 16px;
}

.SuppChatMsgRecivedMsg1 {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 0px !important;
  color: #ffffff;
}

.SuppChatMsgRecivedMsgTimeText1 {
  display: flex;
  justify-content: flex-end;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9a9a9a;
}
.SuppChatMsgRecivedMsgTimeTextAndRepliedBy {
  display: flex;
  width:100%;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9a9a9a;
}

.SuppChatBoxMsgMainBox {
  display: flex;
  flex-direction: row;
  padding: 16px 30px 30px;
}

.SuppChatBoxSendBtnMain {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SuppChatBoxSendBtn {
  width: 115px;
  height: 47px;
  background: #ff6700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 20px 15px 16px;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 8%), 0px 4px 6px rgb(50 50 93 / 10%);
  border-radius: 6px;
  cursor: pointer;
}

.SuppAllChatArrows {
  margin-right: 15px;
  display: contents;
}

.SuppChatBoxSendBtnText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
}

.SuppChatBoxMsgBox {
  width: 529px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 2%), 0px 1px 3px rgb(50 50 93 / 15%);
  border-radius: 6px;
  margin-right: 12px;
}

.SuppChatBoxMsgBoxBottom1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 13px 0px;
}

.SuppChatBoxMsgBoxBottom11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}

.SuppChatBoxMsgBoxBottomText {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #454545;
}

.SuppChatBoxMsgBoxBottomTextBold {
  font-family: "Open Sans";
  font-style: italic;
  font-size: 14px;
  line-height: 19px;
  color: #454545;
  font-weight: 700;
  margin-left: 5px;
  margin-right: 8px;
}

.FileSharingIcon {
  margin-right: 16px;
}

.SuppChatBoxMsgBoxTop1 {
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 1680px) {
  .mainSupportModel {
    /* width: 660px; */
  }

  .SuppInboxText {
    margin: 0px 106px 0px 24px;
  }

  /* .ChatMsgSuppMyInboxText {
    width: 558px;
  } */

  /* .SuppAllChatAgent1 {
    width: 661px;
  } */
}

@media only screen and (max-width: 1600px) {
  .mainSupportModel {
    /* width: 627px */
  }

  /* .ChatMsgSuppMyInboxText {
    width: 532px;
  } */

  .SuppInboxClose {
    margin-left: 30px;
  }

  .SuppInboxText {
    margin: 0px 91px 0px 24px;
  }

  .SuppChatMsgBox {
    height: 500px;
  }

  /* .SuppAllChatAgent1 {
    width: 631px;
  } */
}

@media only screen and (max-width: 1444px) {
  .dashboard-cont {
    margin-left: 190px !important;
  }
}

.font-bold {
  font-weight: bold;
}

.text-end {
  text-align: end;
  padding-right: 2.5rem;
}
.text-center {
  text-align: center;
}

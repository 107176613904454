/* Billing page styling starts here */

/* fontSize: "12px",
  color: "#FFFFFF",
  marginLeft: "12px",
  fontWeight: "600",
  backgroundColor: "#12BF0F",
  height: "22px",
  width: "60px",
  textAlign: "center",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center", */

.export-csv-btn {
  background-color: #fff;
  border: 1px solid #ff6700;
  color: #ff6700;
  margin-right: 5px !important;
  padding: 0.625rem 1.25rem;
  border-radius: 0.375rem;
  transition: all 0.15s ease;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.export-csv-btn:hover {
  border: 1px solid #ff6700;
}

.btn-ext {
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  background-color: #798dff;
  color: #ffffff;
  height: 22px;
  width: 60px;
  text-align: center;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.btn-ext1 {
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  background-color: #dcdcdc;
  height: 22px;
  color: #ffffff;
  width: 60px;
  text-align: center;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}
.btn-ext12 {
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  background-color: #dcdcdc;
  height: 22px;
  color: #ffffff;
  width: 74px;
  text-align: center;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.btn-extPartial {
  font-size: 12px;
  width: 104px !important;
  color: #333333;
  font-weight: 600;
  height: 22px;
  width: 60px;
  color: #ffffff;
  text-align: center;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.transfterDocumentImg {
  width: 200px;
  height: 150px;
}

.invoiceTransferActions {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-bottom: 20px;
}

.btn-toggle {
  background-color: transparent;
  border: none;
}

.invoice-table-data {
  color: #5e72e4;
  display: flex;
  flex-direction: "row";
  align-items: center;
  justify-content: space-around;
}

.tbl-btn {
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  background-color: #798dff;
  color: #ffffff;
  height: 22px;
  width: 60px;
  text-align: center;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.tbl-btn-data {
  font-size: 12px;
  color: #fff;
  height: 18px;
  width: 60px;
}

.header-hr {
  /* height: 1px; */
  border-width: 0;
  color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.2);
  width: 90%;
}

.card-header-first {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-hearder-sec {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 26%; */
}

.billing-details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 50%;
}

.card-shadow {
  min-height: 480px;
  width: 100%;
  height: 100%;
}

.card-header {
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tutor-salries-data {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* width: 66%; */
  margin-left: 15px;
  /* height: 35px; */
}

.tutor-salries-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 36%;
}

.tutor-salries-btn {
  background-color: #ff6700;
  border: 1px solid #ff6700;
  color: #fff;
  width: auto;
  font-size: 80%;
}

.tutor-salaries-foot {
  font-size: 1vw;
  margin-left: 25%;
  color: #9a9a9a;
  font-weight: bold;
}

.tutor-salries-tag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 26%; */
}

.ts-col {
  height: 100%;
}

.billling-table-data {
  color: #5e72e4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.BillingInvoiceModal {
  max-width: 20.677vw !important;
}

.BillingInvoiceModalPayment {
  max-width: 33.802vw !important;
}

/* .BillingInvoiceModal2 {
  max-width: 35vw !important;
} */

.BillingInvoiceModalBox {
  display: flex;
  flex-direction: column;
  height: 39.896vw;
}

.InvoiceClassesModalBox {
  display: flex;
  flex-direction: column;
  padding: 1.563vw 1.563vw 0px 1.563vw;
}

.BillingInvoiceModalBoxPayment {
  display: flex;
  flex-direction: column;
  min-height: 331px;
}

.InvoiceClassesModalBoxPayment {
  display: flex;
  flex-direction: column;
  padding: 1.563vw 1.563vw 0px 1.563vw;
}

.InvoiceModalBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.042vw;
}

.ModalInvoiceText {
  font-size: 1.042vw;
  font-family: Open Sans;
  color: #454545;
}

.ModalClassesBox1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.677vw;
}

.ModalClassesBoxPayment {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.677vw;
}

.ModalClassesBoxText45 {
  font-size: 0.729vw;
  font-weight: 400;
  color: #454545;
}

.ModalClassesBoxTextBlue {
  font-size: 0.729vw;
  font-weight: 700;
  color: #5e72e4;
}

.dont-remove-this {
  margin-bottom: 0px !important;
}

.TDHover {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.TDHover:hover {
  cursor: pointer;
}

.TDHover:focus {
  cursor: pointer;
}

.PrimaryScrollQualification12121 {
  height: 24.7vw;
  overflow-y: scroll;
}

.PrimaryScrollQualification12121::-webkit-scrollbar {
  width: 3px !important;
  margin-right: 5px;
}

.PrimaryScrollQualification12121::-webkit-scrollbar-track {
  background: transparent;
}

.PrimaryScrollQualification12121::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.PrimaryScrollQualificationPayment {
  height: auto;
  overflow-y: scroll;
}

.PrimaryScrollQualificationPayment::-webkit-scrollbar {
  width: 3px !important;
  margin-right: 5px;
}

.PrimaryScrollQualificationPayment::-webkit-scrollbar-track {
  background: transparent;
}

.PrimaryScrollQualificationPayment::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.mui-csvbtn {
  height: 35px;
}

@media only screen and (max-width: 1666px) {
  .mui-csvbtn {
    height: 30px;
  }
}

@media only screen and (max-width: 1444px) {
  .mui-csvbtn {
    height: 25px;
  }
}

/* Billing page styling ends */

.btn_after_button {
  background-color: #ff6700;
  color: #fff;
  margin-left: 15px;
  height: 100%;
}

.btn_after_button:hover {
  background-color: #e05a00;
  color: #fff;
}

.btn_after_button:active {
  background-color: #e05a00;
}

.date_picker {
  /* color:red; */
  background-color: #eee;
}

.billingTopRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 30px 20px;
}

.billingSearchUserBlock {
  display: flex;
  align-items: center;
}

.tutorBillingModal {
  width: 65vw;
  margin: auto !important;
}
.tutorBillingModalContainer {
  padding: 30px;
}
.tutorInfoRow {
  padding-top: 10px;
}

.paymentCardMethod {
  margin-left: 5px;
  background: #ff6700;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
}

.downloadPdfBlock {
  padding-bottom: 20px;
  text-align: center;
}

.downloadPdfHeaderRow {
  display: flex;
  justify-content: space-between;
}

.billingTabsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding-left: 12px !important;
}

.tdTitle {
  color: #5e72e4;
  font-weight: bold;
}

.prepaidTag {
  color: #12bf0f;
}

.postpaidTag {
  color: #9a9a9a;
}

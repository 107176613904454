.tagContainer {
  font-weight: 600;
  font-size: 14px;

  span {
    border-radius: 20px;
    padding: 4px 12px;
  }

  .redeemed,
  .active {
    color: #fff;
    background: #12bf0f;
  }

  .expired {
    color: #fff;
    background: #ee7575;
  }

  .pending,
  .in-active,
  .inactive {
    color: #fff;
    background: #9a9a9a;
    text-transform: capitalize;
  }

  .postpaid {
    color: #fff;
    background: #ee7575;
    text-transform: capitalize;
  }

  .prepaid {
    color: #fff;
    background: #5e72e4;
    text-transform: capitalize;
  }
  .refunded{
    color: #fff;
    background: #ff8a3c;
    text-transform: capitalize;
  }
  .rescheduled {
    color: #fff;
    background: #ff8a3c;
    text-transform: capitalize;
  }

  .terminated,
  .cancelled {
    color: #fff;
    background: #ff3c3c;
    text-transform: capitalize;
  }

  .ongoing {
    color: #fff;
    background: #2dce89;
    text-transform: capitalize;
  }

  .posted {
    color: #fff;
    background: #adadad;
    text-transform: capitalize;
  }
}
.tagContainerSmallText {
  font-weight: 500;
  font-size: 12px;

  span {
    border-radius: 20px;
    padding: 3px 10px;
  }

  .redeemed,
  .active {
    color: #fff;
    background: #12bf0f;
  }

  .expired {
    color: #fff;
    background: #ee7575;
  }

  .pending,
  .in-active,
  .inactive {
    color: #fff;
    background: #9a9a9a;
    text-transform: capitalize;
  }

  .postpaid {
    color: #fff;
    background: #ee7575;
    text-transform: capitalize;
  }

  .prepaid {
    color: #fff;
    background: #5e72e4;
    text-transform: capitalize;
  }
  .refunded{
    color: #fff;
    background: #ff8a3c;
    text-transform: capitalize;
  }
  .rescheduled {
    color: #fff;
    background: #ff8a3c;
    text-transform: capitalize;
  }

  .terminated,
  .cancelled {
    color: #fff;
    background: #ff3c3c;
    text-transform: capitalize;
  }

  .ongoing {
    color: #fff;
    background: #2dce89;
    text-transform: capitalize;
  }

  .posted {
    color: #fff;
    background: #adadad;
    text-transform: capitalize;
  }
}

.jobTag {
  span {
    border-radius: 10px !important;
  }
}

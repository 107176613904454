.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fc-button-group {
  color: #ff6700;
  -webkit-tap-highlight-color: #ff6700 !important;
}

.fc .fc-button-primary {
  background-color: #ff6700;
  border: #ff6700;
  margin-right: 6px;
  -webkit-tap-highlight-color: #ff6700 !important;
}
.fc .fc-button-primary:hover {
  background-color: #ffffff;
  border: 1px solid #ff6700;
  color: #ff6700;
  margin-right: 6px;
  -webkit-tap-highlight-color: #ff6700 !important;
}
.fc .fc-button-primary::after {
  background-color: #ffffff;
  border: 1px solid #ff6700;
  color: #ff6700;
  margin-right: 6px;
  -webkit-tap-highlight-color: #ff6700 !important;
}
.fc .fc-button-primary:active {
  background-color: #ffffff;
  border: 1px solid #ff6700;
  color: #ff6700;
  margin-right: 6px;
  -webkit-tap-highlight-color: #ff6700 !important;
}
.fc .fc-button-primary:focus {
  background-color: #ffffff;
  border: 1px solid #ff6700;
  color: #ff6700;
  margin-right: 6px;
  -webkit-tap-highlight-color: #ff6700 !important;
}
.fc .fc-button-primary.-webkit-tap-highlight-color {
  background-color: #ffffff;
  border: 1px solid #ff6700;
  color: #ff6700;
  margin-right: 6px;
}

.fc .fc-button-primary:disabled {
  -webkit-tap-highlight-color: #ff6700 !important;
  background-color: #ff6700;
  border: #ff6700;
}
.fc .fc-button-primary:disabled:hover {
  -webkit-tap-highlight-color: #ff6700 !important;
  background-color: #ffffff;
  border: 1px solid #ff6700;
  color: #ff6700;
  margin-right: 6px;
}

.prepaidTag {
  display: flex;
  align-items: center;
}

.cut-off-text {
  overflow: hidden;
  text-decoration: line-through;
  display: inline-block;
  max-width: 150px; /* Set the maximum width of the container */
}

.rescheduleBtn {
  padding-top: 8px;
  font-weight: bold;
  background-color: rgb(255, 103, 0);
  color: rgb(255, 255, 255);
  height: 44px;
  border-color: rgb(255, 103, 0);
}

.rescheduleBtn:hover {
  color: #fff;
  background-color: rgb(255, 103, 0);
}

.fc-event-main {
  width: 100%;
}

.classDetailHeader {
  display: flex;
  gap: 20px;
  align-items: center;
}

.classScheduleInfo {
  background-color: #f6f9fc;
  padding: 20px;
}

.classStatusesList {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}

.classScheduleTitle {
  color: #757575;
  font-size: 14px;
  font-weight: 500;
}

.classScheduleValue {
  color: #333333;
  font-size: 18px;
  font-weight: 700;
}

.IsRescheduleClass {
  text-decoration: line-through;
  color: #9a9a9a;
}

.IsRescheduleClassNewInfo {
  /* text-decoration: line-through; */
  color: #ff8a3c;
}

.justify-between {
  justify-content: space-between;
}

.classJobTitleHeading {
  display: flex;
  align-items: center;
  gap: 10px;
}

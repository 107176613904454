.conversationMainBox {
  display: flex;
  flex-direction: column;
  width: 999px !important;
  height: 802px !important;
}

.chatLog {
  display: flex;
  flex-direction: row;
  height: 73px;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}

.conversationHading {
  display: flex;
  flex-direction: row;
}

.unReadCountBlock {
  width: 22px;
  height: 22px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  background-color: #ff6700;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChatMsgSuppMyInbox {
  display: flex;
}

.conversationHadingText1 {
  size: 18px;
  font-weight: 500;
  color: #454545;
  font-family: "Open Sans";
  font-style: normal;
}

.conversationHadingText2 {
  size: 24px;
  font-weight: 700;
  color: #454545;
  font-family: "Open Sans";
  font-style: normal;
}

.conversationDivider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.convDiv {
  height: 2px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #e9ecef;
  background-color: #e9ecef;
}

.SuppChatMsgBox2 {
  display: flex;
  flex-direction: column;
  padding: 0px 30px 0px;
  height: 700px;
  background-color: #f6f9fc;
  overflow-y: scroll;
  padding-bottom: 15px;
}

.SuppAllChatMainBox {
  overflow-y: scroll;
  height: calc(100vh - 280px - 95px);
}

.SuppAllChatMainBox222 {
  height: 729px;
}

.SuppChatMsgBox2::-webkit-scrollbar {
  width: 3px !important;
  margin-right: 5px;
  display: flex;
  flex-direction: column-reverse;
}

.SuppChatMsgBox2::-webkit-scrollbar-track {
  background: transparent;
}

.SuppChatMsgBox2::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.SuppChatMsgBox3 {
  display: flex;
  flex-direction: column;
  padding: 0px 30px 0px;
  height: 500px;
  background-color: #f6f9fc;
  /* overflow-y: scroll; */
  padding-bottom: 15px;
}

.SuppChatMsgBox3::-webkit-scrollbar {
  width: 3px !important;
  margin-right: 5px;
  display: flex;
  flex-direction: column-reverse;
}

.SuppChatMsgBox3::-webkit-scrollbar-track {
  background: transparent;
}

.SuppChatMsgBox3::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #ffd3a8;
  border-radius: 20px;
  margin-right: 5px;
}

.attach-file-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.upload-chat-file {
  width: 200px;
  height: 200px;
  cursor: pointer;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
}
